import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import PageTitle from "../components/PageTitle"
import SliceZone from "../components/SliceZone"

export const query = graphql`{
  prismicAboutPage {
    data {
      meta_description {
        text
      }
      meta_title {
        text
      }
      page_title {
        text
      }
      page_subtitle {
        text
      }
      body{
        ... on PrismicAboutPageDataBody {
          slice_type
          primary {
            align_image
            content {
              text
              type
            }
            image {
              url
            }
            link {
              url
            }
            link_text {
              text
            }
            title {
              text
            }
          }
        }
      }
    }
  }
}
`


const AboutPage = ({data}) => {

  const page = data.prismicAboutPage.data

  return(
    <Layout>
      <Seo title={page.meta_title[0].text} description={page.meta_description[0].text} />
      <PageTitle subtitle={page.page_subtitle[0].text} title={page.page_title[0].text} />
      <SliceZone sliceZone={page.body} />
    </Layout>
  )
}

export default AboutPage
